import React, { useCallback } from "react";
import PhoneInput, { Value as InputValue } from 'react-phone-number-input'
import { GenericInput } from "../types";
import styles from "./Phone.module.scss";
import sharedStyles from "../Shared.module.scss";
import LabeledContainer from "../../containers/LabeledContainer";
import ValidationError from "../../ValidationError";
import { useAppSelector } from "store";

interface Props extends GenericInput<string> {
	defaultCountry: any;
	onChange: (event: InputValue) => void;
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => React.FocusEventHandler<HTMLInputElement> | void;
	error?: string;
	"data-cy"?: string;
}

type PhoneInputLabels = {
	country: string;
	phone: string;
	ext: string;
	[countryCode: string]: string;
};

function getLocale(localeCode: string): PhoneInputLabels {
	try {
		return require(`./locales/${localeCode}.json`);
	} catch (error) {
		console.error(`Failed to load locale: ${localeCode}`, error);
		return require('./locales/en.json');
	}
}

const ConcretePhoneInput = React.forwardRef<typeof PhoneInput, Props>((
	{
		name,
		value,
		defaultCountry,
		onChange,
		style,
		label,
		icon,
		containerClassName = "",
		onBlur,
		error,
		"data-cy": dataCy
	}, ref) => {
	const onFieldChange = useCallback((value: InputValue) => {
		onChange(value);
	}, [onChange])

	const languageCode = useAppSelector((state) => state.app.currentLanguage || state.channel.properties.language?.meta.default);

	return (
		<div className={styles.container}>
			{label
				? (
					<div>
						<LabeledContainer
							style={{ backgroundColor: style?.backgroundColor, border: style?.border }}
							label={label}
							className={`${containerClassName}`}
						>
							{icon && icon}

							<PhoneInput
								data-cy={dataCy}
								international
								labels={getLocale(languageCode)}
								name={name}
								className={styles.input}
								defaultCountry={defaultCountry}
								value={value || ""}
								onChange={(s: InputValue) => s && onFieldChange(s)}
								onBlur={onBlur}
								flagUrl={`https://flagcdn.com/w640/{xx}.png`}
							/>
						</LabeledContainer><ValidationError error={error} />
					</div>
				)
				: (
					<div>
						<div className={`${sharedStyles.container} ${containerClassName}`}
							style={{ "border": style?.border || "inherit" }}>
							{icon && icon}

							<PhoneInput
								data-cy={dataCy}
								international
								name={name}
								labels={getLocale(languageCode)}
								className={styles.input}
								defaultCountry={defaultCountry}
								value={value || ""}
								onChange={(s: InputValue) => s && onChange(s)}
								onBlur={onBlur}
								flagUrl={`https://flagcdn.com/w640/{xx}.png`}
							/>
						</div>
						<ValidationError error={error} />
					</div>
				)
			}
		</div>
	)
});

export default ConcretePhoneInput;
