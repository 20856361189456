import React from 'react'
import useTranslations from "../../../hooks/useTranslations";
import styles from './BookingList.module.scss'
import {IBooking} from '../../../store/portal/portalTypes'
import Booking from "../Booking/Booking";
import Button, {ColourTypes} from "../../../components/inputs/buttons/Button/Button";

interface IBookingListProps {
  bookings: Array<IBooking>;
  offset: number | undefined;
  count: number | undefined;
  onLoadMore: () => void;
}

const BookingList = ({bookings, offset, count, onLoadMore}: IBookingListProps) => {

  const {translation} = useTranslations();
  const bookingTableHeaders = [
    '',
    `${translation.get('portal:booking:code:title')}`,
    `${translation.get('portal:booking:pickup')}`,
    `${translation.get('portal:booking:dropoff')}`,
    `${translation.get('portal:booking:luggage')}`,
    ''
  ];

  return (
    <div className={styles.list}>
      <div className={styles.heading}>{translation.get('portal:booking:title')}</div>
      <div className={styles.container}>
        <div className={styles.row}>
          {bookingTableHeaders.map((header: string, index: number) => <span
            key={index}
            className={styles.columnHeading}>{header}</span>)}
        </div>
        <div>
          {bookings.map((booking: IBooking, index) =>
            <Booking key={index} booking={booking}/>
          )}
        </div>
      </div>
      {offset && count !== bookings.length && <div className={styles.footer}>
        <Button text={translation.get('portal:booking:loadMore')} onClick={onLoadMore} color={ColourTypes.secondary}/>
      </div>}
    </div>
  )
}

export default BookingList
