import {Currency, PickupType} from "../booking/bookingTypes";
import PaginatedResponse from "../paginatedResponse";
import {DateObjectUnits} from "luxon";

export interface IChannelColorsState {
  "primary": string;
  "secondary": string;
  "action": string;
  "background": string;
  "dark": string;
  "footer:background": string;
  "footer:overlay": string;
  "footer:logo": string;
  "footer:text": string;
  "header:background": string;
  "header:button": string;
  "header:navigation::initial:text": string;
  "header:navigation::initial:background": string;
  "header:navigation::initial:logo": string;
  "header:navigation::initial:input:background": string;
  "header:navigation::initial:input:text": string;
  "header:navigation::initial:dropdown:background": string;
  "header:navigation::initial:dropdown:text": string;
  "header:navigation::scrolling:text": string;
  "header:navigation::scrolling:background": string;
  "header:navigation::scrolling:logo": string;
  "header:navigation::scrolling:input:background": string;
  "header:navigation::scrolling:input:text": string;
  "header:navigation::scrolling:dropdown:background": string;
  "header:navigation::scrolling:dropdown:text": string;
  "header:overlay": string;
  "header:text:dark": string;
  "header:text:light": string;
  "input:border": string;
  "input:background": string;
  "input:text:dark": string;
  "input:text:light": string;
  "input::disabled:background": string;
  "input::disabled:text": string;
  "text:dark": string;
  "text:light": string;
}

export interface IChannelVariables {
  "h1::color": string
  "h1::font": string
  "h1::size": string
  "text::color": string
  "text::font": string
  "text:secondary::color": string
  "button:primary::background": string
  "button:primary::color": string
  "button:secondary::background": string
  "button:secondary::color": string
  "header::height": string
  "header::background": string
  "header:inverted::background": string
  "header::text:color": string
  "header:navigation:logo::background": string
  "header:navigation::background": string
  "header:navigation:logo::height": string
  "header:navigation:logo::width": string
  "header:dropdown::background": string
  "header:dropdown::color": string
  "header:navigation:overlay": string
  "header:language:dropdown::background": string
  "header[scrolling]:navigation::background": string
  "header[scrolling]::text:color": string
  "header[scrolling]:dropdown::color": string
  "header[scrolling]:dropdown::background": string
  "header[scrolling]:navigation:logo::background": string
  "header[scrolling]:navigation:logo::height": string
  "header[scrolling]:navigation:input::background": string
  "header[scrolling]:navigation:overlay::background": string
  "language:dropdown:list::background": string
  "language:dropdown::color": string
  "footer::background": string
  "footer:overlay::background": string
  "footer::text:color": string
  "footer:trademark::opacity": string
  "footer:logo::color": string
  "flow::gap": string
  "flow:progress:bar:icon[submitted]::background": string
  "flow:progress:bar:icon[submitted]::color": string
  "flow:progress:bar:line[submitted]::border": string
  "flow:progress:bar:icon[active]::background": string
  "flow:progress:bar:icon[active]::color": string
  "flow:progress:bar:icon[inactive]::background": string
  "flow:progress:bar:line[inactive]::border": string
  "flow:progress:bar:line[inactive]::color": string
  "flow:step::background": string
  "flow:step::color": string
  "flow:step::border:radius": string
  "flow:step:heading::color": string
  "flow:step:subheading::color": string
  "flow:step:completed:heading::color": string
  "flow:step:submit:button::background": string
  "flow:step:submit:button::color": string
  "flow:step:submit:button::border:radius": string
  "flow:step:edit:button::background": string
  "flow:step:edit:button::color": string
  "flow:step:edit:button::border:radius": string
  "flow::error:color": string
  "information::background": string
  "tooltip::color": string
  "checkbox:label::color": string
  "checkbox:label:link::color": string
  "checkbox:checked::color": string
  "checkbox:checked::border": string
  "input::background": string
  "input:disabled::background": string
  "input:label::color": string
  "input::border": string
  "input:focus::border": string
  "input:icon::color": string
  "input:dropdown:list::border": string
  "input:dropdown:option::background": string
  "input:datetime::text:color": string
  "flow:service:selector::border:radius": string
  "flow:service:selector[active]:icon::background": string
  "flow:service:selector[active]::border": string
  "flow:service:selector[active]:text::color": string
  "flow:service:selector[active]::background": string
  "flow:service:selector[inactive]:icon::background": string
  "flow:service:selector[inactive]::border": string
  "flow:service:selector[inactive]:text::color": string
  "flow:service:selector[inactive]::background": string
  "flow:payment:error::background": string
  "flow:payment:error::border:radius": string
  "flow:payment:error::border": string
  "flow:payment:error::color": string
  "info:card::background": string
  "info:card::color": string
  "info:card:heading::color": string
  "info:card:icon::fill": string
  "info:card:error::background": string
  "info:card:error::color": string
  "info:card:error:heading::color": string
  "info:card:error:icon::fill": string
  "modal::background": string
  "modal:heading::color": string
  "map:info:window:name::background": string
  "map:info:window:description::color": string
  "summary:step:card::background": string
  "summary:date:time::color": string
  "price:overview::background": string
  "price:overview::color": string
  "price:overview::border:radius": string
  "payment:method::background": string
  "payment:method::color": string
  [key: string]: string;
}

export interface BlackListWhiteList {
  arrival: {
    blacklist: string[];
    whitelist: string[]
  };
  departure: {
    blacklist: string[];
    whitelist: string[]
  }
}

export interface IChannelTranslationsState {
  "address:short_address": string;
  "address:short_address:placeholder": string;
  "address:short_address_or_address": string;
  "address:address_field": string;
  "address:city": string;
  "address:country": string;
  "address:postalcode": string;
  "address:street:name": string;
  "address:street:number": string;
  "address:street:addition": string;
  "address:community": string,
  "address:neom_community_1": string,
  "address:neom_community_2": string,
  "address::community:type:office": string,
  "address::community:type:cabin": string,
  "address:type": string,
  "button:cancel": string;
  "button:confirm": string;
  "button:edit": string;
  "button:next": string;
  "button:submit": string;
  "flight:heading": string;
  "flight:select:heading": string;
  "flight:select:subHeading:airport": string;
  "flight:select:subHeading:city": string;
  "flight:subHeading:pnr": string;
  "flight:completed_heading": string;
  "flight:number:heading": string;
  "flight:number:label_departure-date": string;
  "flight:number:label_flight-number": string;
  "flight:number:label_pnr": string;
  "flight:number:label_pnr:info": string;
  "flight:number:label_last_name": string;
  "flight:search:heading": string;
  "flight:search:label_airline": string;
  "flight:search:label_from": string;
  "flight:search:label_to": string;
  "flight:switch_number": string;
  "journey_creation:route_heading": string;
	"journey_creation:heading": string;
  "header:progress_completed": string;
  "header:subtitle": string;
  "header:slogan": string;
  "header:title": string;
  "header:menu:home": string;
  "header:menu:airport_delivery": string;
  "header:menu:city_delivery": string;
  "header:menu:become_partner": string;
  "location:address_placeholder": string;
  "location:bagpoint_placeholder": string;
  "location:completed_heading": string;
  "location:confirmation_address": string;
  "location:confirmation_date": string;
  "location:confirmation_time": string;
  "location:first_policy_checkbox": string;
  "location:first_policy_checkbox_nolink": string;
  "location:first_policy_checkbox_nolink:terms": string;
  "location:heading:airport": string;
  "location:heading:city": string;
  "location:heading:title": string;
  "location:heading:info:title": string;
  "location:label_date:airport": string;
  "location:label_date:city": string;
  "location:label_luggage:airport": string;
  "location:label_luggage:city": string;
  "location:label_time": string;
  "location:second_policy_checkbox": string;
  "location:switch_address": string;
  "location:switch_bagpoint": string;
  "location:zone_error": string;
  "location:heading:airport_completed": string;
  "location:heading:city_completed": string;
  "location:heading:community_completed_single": string;
  "location:heading:community_completed_plural": string;
	"location:community:info_box": string;
  "login:checkbox": string;
  "login:invalid_grant": string;
  "login:email": string;
  "login:password": string;
  "login:forgot_password": string;
	"login:heading": string;
	"login:subheading": string;
	"login:completed_heading": string;
  "password_change:new_password": string;
  "password_change:repeat_password": string;
  "password_change:error": string;
  "password_reset:back_to_login": string;
  "password_reset:request": string;
	"password_reset:link": string;
  "payment:heading": string;
  "payment:label_contact": string;
  "payment:label_create": string;
  "payment:label_email": string;
  "payment:label_confirm_email": string;
  "payment:label_first_name": string;
  "payment:label_last_name": string;
  "payment:label_password": string;
  "payment:label_passport": string;
  "payment:label_passport:info": string;
  "payment:label_phone": string;
  "payment:label_repeat_password": string;
  "payment:verification:title": string,
  "payment:verification:subtitle": string,
  "payment:verification:label": string,
  "payment:verification:error": string,
  "payment:verification:success": string,
  "payment:verification:code_resend": string,
  "error:general_error": string;
  "error:title": string;
  "payment:result:Cancelled": string;
  "payment:link:error": string;
	"payment:link:confirm": string;
	"payment:link:cancel": string;
	"payment:link:confirmation_heading": string;
  "payment:link:success": string;
  "payment:link:resend": string;
  "payment:link:book_again": string;
	"payment:link:resend:error": string;
  "payment:result:Error": string;
  "payment:result:Expired": string;
  "payment:result:Refused": string;
  "payment:result_message:button_failure": string;
  "payment:result_message:button_redirect": string;
  "payment:result_message:button_success": string;
  "payment:security": string;
  "payment:switch_login": string;
  "payment:switch_registration": string;
  "payment:switch_new": string;
  "payment:booking_selector:personal": string;
  "payment:booking_selector:non_personal": string;
  "portal:account:email": string;
  "portal:account:name": string;
  "portal:account:firstName": string;
  "portal:account:lastName": string;
  "portal:account:phone": string;
  "portal:account:title": string;
  "portal:booking:code": string;
  "portal:booking:code:title": string;
  "portal:booking:flightNumber": string;
  "portal:booking:status": string;
  "portal:booking:contact:firstName": string;
  "portal:booking:contact:lastName": string;
  "portal:booking:contact:email": string;
  "portal:booking:contact:phone": string;
  "portal:booking:contact:title": string;
  "portal:booking:pickup": string;
  "portal:booking:pickup:time": string;
  "portal:booking:dropoff": string;
  "portal:booking:dropoff:time": string;
  "portal:booking:loadMore": string;
  "portal:booking:luggage": string;
  "portal:booking:title": string;
  "portal:booking:partner:tenant": string;
  "portal:booking:partner:title": string;
  "portal:booking:partner:email": string;
  "portal:booking:passenger:title": string;
  "portal:booking:passenger:name": string;
  "portal:booking:passenger:items": string;
  "portal:booking:payment:net": string;
  "portal:booking:payment:tax": string;
  "portal:booking:payment:total": string;
  "portal:booking:payment:title": string;
  "portal:location:street": string;
  "portal:location:number": string;
  "portal:location:zip": string;
  "portal:location:city": string;
  "portal:location:title": string;
  "portal:location:loadMore": string;
  "price_overview:luggage": string;
  "price_overview:luggage_multiple": string;
  "price_overview:no-address": string;
  "validation:flight:recaptcha_heading": string;
  "validation:flight:recaptcha": string;
  "validation:flight:date_departed": string;
  "validation:flight:date_offset_invalid": string;
  "validation:flight:flight_number_invalid": string;
  "validation:flight:flight_number_required": string;
  "validation:flight:pnr_invalid": string;
  "validation:flight:pnr_required": string;
  "validation:flight:pnr_too_long": string;
  "validation:flight:pnr_length": string;
  "validation:flight:pnr_no_special_char": string;
  "validation:flight:wrong_airline": string;
  "validation:flight:wrong_departure": string;
  "validation:flight:wrong_arrival": string;
  "validation:flight:no_allowance": string;
  "validation:input:email_invalid": string;
  "validation:input:email_required": string;
  "validation:input:email_confirmation_invalid": string;
  "validation:input:firstname_invalid": string;
  "validation:input:firstname_required": string;
  "validation:input:lastname_invalid": string;
  "validation:input:lastname_required": string;
  "validation:input:password_no_digit": string;
  "validation:input:password_no_letters": string;
  "validation:input:password_no_match": string;
  "validation:input:password_required": string;
  "validation:input:password_short": string;
  "validation:input:passport_required": string;
  "validation:input:passport_invalid": string;
  "validation:input:phone_invalid": string;
  "validation:input:phone_required": string;
  "validation:input:street_too_long": string;
  "validation:input:street_required": string;
  "validation:input:number_too_long": string;
  "validation:input:addition_too_long": string;
  "validation:input:postalCode_too_long": string;
  "validation:input:city_too_long": string;
  "validation:input:city_required": string;
  "validation:input:country_too_long": string;
  "validation:input:country_required": string;
  "validation:input:luggage": string;
  "validation:input:short_address_incorrect": string;
  "validation:internal_error": string;
  "validation:location:empty_address": string;
  "validation:location:empty_checkboxes": string;
  "validation:location:empty_timeslot": string;
  "validation:location:timeslot_placeholder": string;
  "validation:location:unavailable_timeslot": string;
	"validation:location:address_not_found": string;
  "validation:input:passport_duplicate": string;
  "thank_you:pickup:warning": string;
  "thank_you:booking_number": string;
  "thank_you:pickup:title:active": string;
  "thank_you:pickup:title": string;
  "thank_you:pickup:tenant": string;
  "thank_you:dropoff:title:active": string;
  "thank_you:dropoff:title": string;
  "thank_you:dropoff:subtitle": string;
  "thank_you:flight:title": string;
  "thank_you:message:title:Authorised": string;
  "thank_you:message:title:Pending": string;
  "thank_you:message:title:Received": string;
  "thank_you:message:subtitle:Authorised": string;
  "thank_you:message:subtitle:Pending": string;
  "thank_you:message:subtitle:Received": string;
  "thank_you:message:contact": string;
  "thank_you:message:myBookings": string;
  "thank_you:message:bookAgain": string;
  "thank_you:restrictions:prohibited:title": string;
  "thank_you:restrictions:prohibited:dangerousGoods": string;
  "thank_you:restrictions:prohibited:batteries": string;
  "thank_you:restrictions:prohibited:magnets": string;
  "thank_you:restrictions:prohibited:flammableItems": string;
  "thank_you:restrictions:cabin:title": string;
  "thank_you:restrictions:cabin:electronicDevices": string;
  "thank_you:restrictions:cabin:liquidsLowVolume": string;
  "thank_you:restrictions:checkedIn:title": string;
  "thank_you:restrictions:checkedIn:sharpObjects": string;
  "thank_you:restrictions:checkedIn:firearm": string;
  "thank_you:restrictions:checkedIn:liquidsHighVolume": string;
  "thank_you:restrictions:onPerson:title": string;
  "thank_you:restrictions:onPerson:lighter": string;
  "thank_you:restrictions:onPerson:esmoke": string;
  "error:flights:E_FLIGHT_IMPORT_ERROR_001": string;
  "error:flights:E_FLIGHT_IMPORT_ERROR_002": string;
  "error:flights:E_FLIGHT_IMPORT_ERROR_003": string;
  "error:flights:E_FLIGHT_IMPORT_ERROR_004": string;
  "error:flights:E_FLIGHT_IMPORT_ERROR_005": string;
  "error:flights:E_FLIGHT_IMPORT_ERROR_006": string;
  "error:flights:E_FLIGHT_IMPORT_ERROR_008": string;
  "error:flights:E_FLIGHT_IMPORT_ERROR_011": string;
  "error:flights:E_FLIGHT_IMPORT_ERROR_012": string;
  "error:flights:E_FLIGHT_IMPORT_ERROR_013": string;
  "error:flights:E_FLIGHT_IMPORT_ERROR_014": string;
  "error:flights:no_available_timeslot": string;
  "error:flights:E_PNR_NOT_FOUND": string;
  "error:flights:E_INVALID_COMBINATION": string;
  "error:flights:E_AMBIGUOUS_LAYOVER_TIME": string;
  "error:flights:E_UNKNOWN_ERROR": string;
  "error:flights:E_UNSUPPORTED_ROUTE_FOUND": string;
  "error:flights:E_NO_SERVICEABLE_JOURNEYS": string;
  "error:flights:E_INVALID_PNR_FORMAT": string;
  "footer:trademark": string;
  "footer:copyright": string;
  "footer:about": string;
  "footer:contact": string;
  "footer:company": string;
  "footer:slogan": string;
  "footer:terms:title": string;
  "footer:terms:link": string;
  "footer:privacy:title": string;
  "footer:privacy:link": string;
  "footer:faq": string;
  "footer:powered_by": string;
  "account_selector:account": string;
  "account_selector:logout": string;
  "service:airport": string;
  "service:city": string;
  "price_overview:total": string;
  "geo_form:description": string;
  "membership:heading": string;
  "membership:field": string;
  "membership:error": string;
  "membership:completed_heading": string;
  "map:info_window:explanation": string;
  "faq:city_delivery:title": string;
  "faq:city_delivery:length": string;
  "faq:city_delivery:question:1": string;
  "faq:city_delivery:answer:1": string;
  "faq:city_delivery:question:2": string;
  "faq:city_delivery:answer:2": string;
  "faq:city_delivery:question:3": string;
  "faq:city_delivery:answer:3": string;
  "faq:city_delivery:question:4": string;
  "faq:city_delivery:answer:4": string;
  "faq:city_delivery:question:5": string;
  "faq:city_delivery:answer:5": string;
  "faq:airport_delivery:title": string;
  "faq:airport_delivery:length": string;
  "faq:airport_delivery:question:1": string;
  "faq:airport_delivery:answer:1": string;
  "faq:airport_delivery:question:2": string;
  "faq:airport_delivery:answer:2": string;
  "faq:airport_delivery:question:3": string;
  "faq:airport_delivery:answer:3": string;
  "faq:airport_delivery:question:4": string;
  "faq:airport_delivery:answer:4": string;
  "faq:airport_delivery:question:5": string;
  "faq:airport_delivery:answer:5": string;
  "faq:on_the_day:title": string;
  "faq:on_the_day:length": string;
  "faq:on_the_day:question:1": string;
  "faq:on_the_day:answer:1": string;
  "faq:on_the_day:question:2": string;
  "faq:on_the_day:answer:2": string;
  "faq:on_the_day:question:3": string;
  "faq:on_the_day:answer:3": string;
  "faq:on_the_day:question:4": string;
  "faq:on_the_day:answer:4": string;
  "faq:on_the_day:question:5": string;
  "faq:on_the_day:answer:5": string;
  "faq:our_services:title": string;
  "faq:our_services:length": string;
  "faq:our_services:question:1": string;
  "faq:our_services:answer:1": string;
  "faq:our_services:question:2": string;
  "faq:our_services:answer:2": string;
  "faq:our_services:question:3": string;
  "faq:our_services:answer:3": string;
  "faq:our_services:question:4": string;
  "faq:our_services:answer:4": string;
  "faq:our_services:question:5": string;
  "faq:our_services:answer:5": string;
  "faq:our_services:question:6": string;
  "faq:our_services:answer:6": string;
  "faq:our_services:question:7": string;
  "faq:our_services:answer:7": string;
  "faq:our_services:question:8": string;
  "faq:our_services:answer:8": string;
  "faq:our_services:question:9": string;
  "faq:our_services:answer:9": string;
  "faq:luggage_allowance:title": string;
  "faq:luggage_allowance:length": string;
  "faq:luggage_allowance:question:1": string;
  "faq:luggage_allowance:answer:1": string;
  "faq:luggage_allowance:question:2": string;
  "faq:luggage_allowance:answer:2": string;
  "faq:luggage_allowance:question:3": string;
  "faq:luggage_allowance:answer:3": string;
  "faq:luggage_allowance:question:4": string;
  "faq:luggage_allowance:answer:4": string;
  "faq:luggage_allowance:question:5": string;
  "faq:luggage_allowance:answer:5": string;
  "faq:security:title": string;
  "faq:security:length": string;
  "faq:security:question:1": string;
  "faq:security:answer:1": string;
  "faq:security:question:2": string;
  "faq:security:answer:2": string;
  "faq:security:question:3": string;
  "faq:security:answer:3": string;
  "faq:security:question:4": string;
  "faq:security:answer:4": string;
  "faq:troubleshooting:title": string;
  "faq:troubleshooting:length": string;
  "faq:troubleshooting:question:1": string;
  "faq:troubleshooting:answer:1": string;
  "faq:troubleshooting:question:2": string;
  "faq:troubleshooting:answer:2": string;
  "faq:troubleshooting:question:3": string;
  "faq:troubleshooting:answer:3": string;
  "faq:statements:title": string;
  "faq:statements:length": string;
  "faq:statements:1": string;
  "faq:statements:2": string;
  "faq:statements:3": string;
  "faq:statements:4": string;
  "faq:statements:5": string;
  "faq:statements:6": string;
  "faq:statements:7": string;
  "faq:statements:8": string;
  "faq:statements:9": string;
  "faq:statements:10": string;
  "faq:statements:11": string;
  "faq:statements:12": string;
  "faq:statements:13": string;
  "faq:statements:14": string;
  "faq:statements:15": string;
  "faq:statements:16": string;
  "faq:statements:17": string;
  "faq:statements:18": string;
  "faq:statements:19": string;
  "faq:statements:20": string;
  "faq:statements:21": string;
  "faq:statements:22": string;
  "faq:statements:23": string;
  "faq:statements:24": string;
  "faq:statements:25": string;
  "cookies:title": string;
  "cookies:modal:title": string;
  "cookies:necessary:title": string;
  "cookies:necessary:content": string;
  "cookies:statistic:title": string;
  "cookies:statistic:content": string;
  "cookies:button:back": string;
  "cookies:button:rejectAll": string;
  "cookies:button:openSettings": string;
  "cookies:button:acceptAll": string;
  "cookies:button:saveSetting": string;
  "flow:input:label": string;
  "flow:heading": string;
  "flow:completed_heading": string;
  "flow:subtitle:city": string;
  "flow:subtitle:airport": string;
  "flow:title:city": string;
  "flow:title:community": string;
  "flow:title:airport": string;
  "city:luggage:pickup:title": string;
  "city:luggage:pickup:subtitle": string;
  "email:verified:success": string;
  "email:verified:error": string;
  "email:verified:button": string;
  "passenger:title": string;
  "passenger:subtitle": string;
  "passenger:flight_info:flight": string;
  "passenger:flight_info:date": string;
  "passenger:flight_info:from": string;
  "passenger:flight_info:to": string;
  "passenger:flight_info:departure": string;
  "passenger:flight_info:booking_code": string;
  "passenger:passenger_info:prefix": string;
  "passenger:passenger_info:first_name": string;
  "passenger:passenger_info:last_name": string;
  "passenger:passenger_info:full_name": string;
  "passenger:passenger_info:allowance": string;
  "passenger:passenger_info:passport": string;
  "passenger:passenger_info:luggage": string;
  "passenger:passenger_info:shared_allowance": string;
  "passenger:completed_heading": string;
  "location:favourite": string;
  "location:address:notfound": string;
  "location:selectAddress": string;
  "registration:submitButton": string;
  "registration:loginLink": string;
  "login:submitButton": string;
  "login:registration": string;
  "login:registrationLink": string;
  "registration:login": string;
  "registration:success": string;
  "password_reset:request_sent": string;
  "password_reset:success": string;
  "validation:location:address_not_serviceable": string;
  "journey_creation:city:completed_heading": string;
  "journey_creation:airport:completed_heading": string;
}

export interface ChannelProperties {
  flows: FlowOption;
  language: {
    meta: {
      default: string;
      available: string[];
      repr: { [key: string]: string }
      adyen: { [key: string]: string }
      ietf: { [key: string]: string }
    };
    translations: { [key: string]: IChannelTranslationsState }
  };
  flightSearch: {
    airline: BlackListWhiteList;
    airport: BlackListWhiteList;
    country: BlackListWhiteList;
    mock?: boolean;
    connector?: string;
    pnr?: {
      length: string;
    }
  };
  payments: {
    config: {};
    methods: Methods
  }
  logistics: {
    boundaries: {
      [key in Flow]: {
        flight?: DurationRange;
        today?: DurationRange
      }
    };
    offsets: {
      [key in Flow]: OffsetRules
    };
    windows: {
      [key in Flow]: Required<DurationRange>[]
    },
	  vacations: Array<Validity>;
  };
  whitelabel: WhiteLabel;
  switches: Switches;
  regulations: IRegulations;
  faq: Array<string>;
  announcements: Array<IAnnouncement>
  content: Content;
  terms: TermsProperties;

  [key: string]: any;
}

export interface OffsetRules {
  pickup: DateObjectUnits;
  delivery: DateObjectUnits
}

export interface DurationRange {
  from?: DateObjectUnits;
  to?: DateObjectUnits
}

export interface Switch<E> {
  default: E;
  display: boolean;
}

export interface Switches {
  flight: Switch<string>;
  location: Switch<PickupType>;
  user: Switch<UserType>;
  flow: Switch<Flow>;
  addressStyle: AddressStyle;
  phoneNumberRequired: boolean;
  validation: string;
  fixedHeaderDirection: boolean;
  fixedFooterDirection: boolean;
  infoWindowTimer: number;
  searchBar: boolean;
  displayProvider: boolean;
  navigation: boolean;
}

export interface IAnnouncement {
  title: string;
  message: string;
  closable: boolean;
  validity: Validity;
}

export interface Validity {
  from: DateTimeObj
  to: DateTimeObj
}

export interface DateTimeObj {
  year: number
  month: number
  day: number
  hour: number
  minute: number
  timeZoneName: string
}

export interface IAccordionSection {
  title: string;
  content: string;
}

export interface Methods {
  whitelist: Array<string>;
  blacklist: Array<string>;
}

export interface WhiteLabel {
  company: {
    url: string;
    name: string;
    legalName: string;
    slogan: string;
    footerSlogan: string;
    email: string;
    phone: string;
    address: string;
  };
  images: {
    [key: string]: string;
    mobileLogo: string;
    desktopLogo: string;
    tabletLogo: string;
  };
  variables: IChannelVariables;
}

export interface Section {
  subtitle?: string;
  statements: string[];
}

export interface TermsContent {
  title: string;

  [key: string]: Section | string;
}

export interface TermsProperties {
  [key: string]: TermsContent;
}

export interface Content {
  showHeaderSlogan: boolean
  showPriceOverview: boolean
  headerMenu: MenuItem[]
  flightNumberPlaceholder?: string;
  luggageLimit?: number;
	logoAdditon?: string;
	passengerNamePercentageShown?: number;
}

export interface MenuItem {
  value: string
  label: keyof IChannelTranslationsState
  isLink: boolean
}

export type IRegulations = {
  prohibited: IRegulationSection;
  cabin: IRegulationSection;
  checkedIn: IRegulationSection;
  onPerson: IRegulationSection
}

export interface IRegulationSection {
  name: string;
  items: Array<IRegulationItem>
}

export interface IRegulationItem {
  name: string;
  icons: Array<string>
}

export interface StepOrder {
  step: number;
  icon: string;
  component: JSX.Element;
  date?: string;
  hour?: string;
  timeZone?: string;
}

export enum AddressStyle {
  DEFAULT = "Default",
  MIDDLE_EAST = "Middle-East"
}

export enum UserType {
  Login = 'Login',
  Anonymous = 'Anonymous'
}


export interface IChannel {
  id: number;
  name: string;
  host: string;
  type: string | Array<string>;
  merchant: string;
  country: string;
  currency: Currency;
  company: {
    id?: number;
    name?: string;
    email?: string;
  };
  properties: ChannelProperties;
}

export enum Flow {
  Airport = "airport",
  City = "city"
}

export enum Steps {
  LOGIN = 'LoginStep',
  FLIGHT = 'FlightStep',
  PNR_FLIGHT = 'PnrFlightStep',
  PNR_PAX_SELECTION = 'PaxSelectionStep',
  LOCATION = 'LocationStep',
  NEOM_LOCATION = 'NeomLocationStep',
  NEOM_PAYMENT = 'NeomPaymentStep',
  PAYMENT = 'PaymentStep',
  VERIFICATION = 'AltanfeethiStep',
  JOURNEY_CREATION = 'JourneyCreationStep'
}

export interface FlowOption {
  [key: string]: {
    title?: string
    icon?: string;
    components: Steps[]
  }
}

export interface IChannelState extends IChannel {
  timestamp?: string;
	error: string | null;
}

export interface IPaginatedChannel extends PaginatedResponse<IChannelColorsState> {
}

export interface FlightErrorCodes {
  "E_FLIGHT_IMPORT_ERROR_001": string;
  "E_FLIGHT_IMPORT_ERROR_002": string;
  "E_FLIGHT_IMPORT_ERROR_003": string;
  "E_FLIGHT_IMPORT_ERROR_004": string;
  "E_FLIGHT_IMPORT_ERROR_005": string;
  "E_FLIGHT_IMPORT_ERROR_006": string;
  "E_FLIGHT_IMPORT_ERROR_008": string;
  "E_FLIGHT_IMPORT_ERROR_011": string;
  "E_FLIGHT_IMPORT_ERROR_012": string;
  "E_FLIGHT_IMPORT_ERROR_013": string;
  "E_FLIGHT_IMPORT_ERROR_014": string;
  "no_available_timeslot": string;
  "E_PNR_NOT_FOUND": string;
  "E_INVALID_COMBINATION": string;
  "E_AMBIGUOUS_LAYOVER_TIME": string;
  "E_UNKNOWN_ERROR": string;
  "E_UNSUPPORTED_ROUTE_FOUND": string;
  "E_NO_SERVICEABLE_JOURNEYS": string;
  "E_INVALID_PNR_FORMAT": string;
}

export enum FlightErrorCodesEnum {
  E_FLIGHT_IMPORT_ERROR_001 = "E_FLIGHT_IMPORT_ERROR_001",
  E_FLIGHT_IMPORT_ERROR_002 = "E_FLIGHT_IMPORT_ERROR_002",
  E_FLIGHT_IMPORT_ERROR_003 = "E_FLIGHT_IMPORT_ERROR_003",
  E_FLIGHT_IMPORT_ERROR_004 = "E_FLIGHT_IMPORT_ERROR_004",
  E_FLIGHT_IMPORT_ERROR_005 = "E_FLIGHT_IMPORT_ERROR_005",
  E_FLIGHT_IMPORT_ERROR_006 = "E_FLIGHT_IMPORT_ERROR_006",
  E_FLIGHT_IMPORT_ERROR_008 = "E_FLIGHT_IMPORT_ERROR_008",
  E_FLIGHT_IMPORT_ERROR_011 = "E_FLIGHT_IMPORT_ERROR_011",
  E_FLIGHT_IMPORT_ERROR_012 = "E_FLIGHT_IMPORT_ERROR_012",
  E_FLIGHT_IMPORT_ERROR_013 = "E_FLIGHT_IMPORT_ERROR_013",
  E_FLIGHT_IMPORT_ERROR_014 = "E_FLIGHT_IMPORT_ERROR_014",
  no_available_timeslot = "no_available_timeslot",
  E_PNR_NOT_FOUND = "E_PNR_NOT_FOUND",
  E_INVALID_COMBINATION = "E_INVALID_COMBINATION",
  E_AMBIGUOUS_LAYOVER_TIME = "E_AMBIGUOUS_LAYOVER_TIME",
  E_UNKNOWN_ERROR = "E_UNKNOWN_ERROR",
  E_UNSUPPORTED_ROUTE_FOUND = "E_UNSUPPORTED_ROUTE_FOUND",
  E_NO_SERVICEABLE_JOURNEYS = "E_NO_SERVICEABLE_JOURNEYS",
  E_INVALID_PNR_FORMAT = "E_INVALID_PNR_FORMAT"
}

export default IChannelState;